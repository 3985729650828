/* Overall Section */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

/* Services Section */
.services-section {
  text-align: center;
  font-family: 'Inter', sans-serif;
}

#ser-heading {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 10px;
}

.services-section p {
  font-size: 16px;
  color: blackl;
  margin-bottom: 80px;
}

/* Services Container */
.services {
  display: flex;
  justify-content: center;
  gap: 55px;
}

/* Service Box */
.service-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/* Header Box */
.header-box {
  width: 220px;
  height: 50px;
  background-color: #fff;
  border: 0.6px solid black;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  z-index: 2;
  color: #356032;
}

/* Vertical Line */
.service-box::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 30px;
  background-color: black;
  top: 50px;
}


/* Content Box */
.content-box {
  width: 380px;
  height: 440px;
  border: 1px solid black;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  margin-top: 28px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  gap: 25px;
}

/* Service Item */
.service-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.service-item img {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.service-item p {
  flex-grow: 1;
  font-size: 16px;
  margin: 0;
  text-align: left;
  line-height: 1.2;
  
 
}

.service-btn {
  padding: 5px 15px;
  font-size: 14px;
  border: none;
  background-color: rgb(49, 47, 47);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  width: 100px;
}

.service-btn:hover {
  background-color: rgb(197, 193, 193);
  color: black;
  
}