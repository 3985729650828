.banner-section-for-all img{
    width: 95%;
    height: 800px;
    border-radius:16px ;
    margin: 2rem;
    
}
.btn-primary{
    width: 120px;
    height: 40px;
    font-size: 12px;
    border-radius: 8px;
    font-weight: 600;
    margin-top: 26px;
    background-color: black;
    color: white;
}
.homeloan-line{
    position: relative;
    bottom: 507px;
    display: inline-block;
    width: auto;
    height: 340px;
    text-transform: capitalize;
    
}
.homeloan-line img{
    display: block;
}
.homeloan-line span {
    position: absolute;
    top: 250px;
    left: 300px;
    width: 500px;
    font-size: 1.5rem;
    background-color: transparent;
}
.homeloan-line button{
    position: absolute;
    top: 325px;
    left: 298px;
}
.homeloan-line button a{
    background-color: black;
    color: whitesmoke;
    text-decoration: none;
}
.link-address{
    margin-left:2rem ;
    font-size: .9rem;
    margin-top: 5px;
   
}
.link-address a{
    color: rgb(171, 159, 159);
    text-decoration: none;
    
   
}
.link-address a:hover{
    color: rgb(16, 16, 16);
    font-weight: 500;
    
   
}