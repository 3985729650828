*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #FCFDF8;
    
}
html {
    scroll-behavior: smooth;
  }
  
.p{
    font-family: 'Montserrat';
    text-align: justify;
}
#hero-para{
    width: 400px;
}
.container{
    display: flex;
}
.left-half{
    width: 50%;
    height: 650px;
    margin-left: 10px;
    padding: 2rem;
}
#sticker{
    background-color: #C9E1E5;
    width: 4rem;
    padding: 5px;
    border-radius: 8px;
}
#hero-line{
    font-size: 3.5rem;
    font-family: 'Montserrat';
    font-weight: 580;
}
#highlighted{
    color:#C70505;
    background-color: #F067AF;
    padding: 8px;
    margin: 2px;
    border-radius: 16px;
    font-weight: 600;
}
.tickbox{
    position: relative;
    top: 3px;
    
}
#hero-tick{
    margin-right: 10px;
    
}
#fainted{
    font-weight: 400;
    color: #928A8A;
}
.line{
    width: 60%;
}

.right-half{
    width: 50%;
    height: 650px;
    /* border: 1px solid rgb(197, 194, 194); */
    margin-right: 20px;
    border-radius: 16px;
    box-shadow: #928A8A;
}
.heroImage{
    width: 615px;
    height: 545px;
    margin-left: 50px;
}
#company-tagline{
    font-size: 1.5rem;
    margin-left: 10rem;
    font-family: "montserrat";
    font-weight: 500;
    position: relative;
    top: 20px;
}


.hero-carousel {
    width: 80%;
    margin: 0 auto;
    height: 500px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
  }
  
  .carousel-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    transition: all 0.5s ease-in-out; /* Smooth transitions */
  }
  
  .text-content {
    flex: 1;
    margin-right: 20px;
  }
  
  .text-content h1 {
    font-size: 1.8rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .hero-button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    top: 2rem;
    transition: background-color 0.3s ease;
  }
  .hero-button a{
    text-decoration: none;
    background: transparent;
    color: white;
  }
  
  .hero-button:hover {
    background-color: #2980b9;
  }
  
  .image-content {
    flex: 1;
    text-align: center;
  }
  
  .image-content img {
    max-width: 100%;
    width: 400px;
    height: auto;
    border-radius: 5px;
  }
  
  /* Carousel Indicators */
  .carousel-indicators {
    text-align: center;
    margin-top: 15px;
  }
  
  .dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .dot.active {
    background-color: #3498db;
  }
  