.documentation-card-container{
    /* border: 1px solid black; */
    display: flex;
    margin :2rem;
    
}
.documentation-card-left{
    width: 30%;
    height: 800px;
    border: 1px solid #32974E;
    padding: 1.3rem;
    line-height: 2rem;
    text-align: left;
    border-radius: 16px;
    margin: 2rem;
    box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.1);
}
.documentation-card-right{
    width: 30%;
    height: 800px;
    padding: 1.3rem;
    line-height: 2rem;
    /* text-align: justify; */
    border-radius: 16px;
    margin-left:5rem ;
    box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.1);
}
#documentation-card-middle{
    width: 30%;
    height: 800px;
    padding: 1.3rem;
    line-height: 2rem;
    text-align: left;
    border-radius: 16px;
    margin-left:5rem ;
    border: 1px solid violet;
    box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.1);
}
.documentation-card-left h3{
    text-decoration: underline;
}
#documentation-card-right{
    border: 1px solid #506AEC;
}