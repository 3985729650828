
.whyUs-container{
display: flex;
margin: 1rem;
margin-top: 8rem;
height: 640px;
}

#whyus{
    margin-left:4rem;
    position: relative;
    top: 5rem;
    color: rgb(236, 130, 24);
    font-weight: 600;
    font-size: 2rem;
}

.left-half-container{
width: 40%;
/* border: 1px solid black; */
padding-left: 3rem;
}
.left-half-container h1 {
    background-color: #2A2828;
    margin-top: 12px;
    font-size: 3rem;
    font-weight: 700;
    background: linear-gradient(
      90deg,
      #20adc1 0%,
      #e0406b 25%,
      #f5d01c 50%,
      #bccc9d 100%
    );
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
  }
.appointmentButton{
    background-color: #506AEC; /* Button background color */
    color: white; /* Text color */
    font-size: 16px; /* Font size */
    padding: 10px 20px; /* Padding inside the button */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-weight: 600;
    transition: background-color 0.3s ease; /* Smooth hover transition */
}
.appointmentButton:hover {
    background-color: #1e90ff; /* Darker sky-blue on hover */
    /* color: black; */
  }
  .appointmentButton a{
    background-color: #506AEC;
    text-decoration: none;
    color: white;
  }
  .appointmentButton a:hover{
    background-color: #1e90ff;
  }




.left-half-container img{
    width: 380px;
    height: 380px;

}

.right-half-container{
    width: 60%;
/* border: 1px solid black; */

}
