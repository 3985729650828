.statistic-container {
  height: 500px;
  /* border: 1px solid red; */
  background-color: #2A2828;
}
.statistic-container h1 {
  background-color: #2A2828;
  margin-top: 30px;
  font-size: 3rem;
  font-weight: bold;
  background: linear-gradient(
    90deg,
    #20adc1 0%,
    #e0406b 25%,
    #f5d01c 50%,
    #bccc9d 100%
  );
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}
.statistic-container p {
  background-color: #2A2828;
  color: white;
}
.statistic-container center {
  background-color: #2A2828;
}
.statistic-holder-container {
  height: 300px;
  border: 1px solid pink;
  width: 70%;
}
.experienceSection {
  border: 1px solid blue;
  height: 250px;
  width: 900px;
  display: flex;
  margin-top: 50px;
  padding-top: 25px;
  border-radius: 16px;
}
#exception {
  background-color: white;
  color: black;
}
.experienceContent {
  width: 25%;
  height: 200px;
}
.experienceContent h2 {
  font-size: 2rem;
}
.experienceContent p {
  font-size: 14px;
  margin-top: 4px;
}
.experienceContent img {
  width: 125px;
  height: 120px;
}

#experienceText {
    color:#20ADC1;
}
#branchText{
    color:#f5d01c;
}
#disbursalText{
    color: #bccc9d;
}
#partnerText{
    color: #e0406b;
}