/* ContactOptions.css */
.contact-options {
    text-align: center;
    padding: 20px;
  }
  
  .contact-options h2 {
    font-size: 1.8rem;
    margin-bottom: 40px;
  }
  
  .options-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .option-card {
    background-color: #FAF9F6;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 220px;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid black;
  }
  
  .option-card .icon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
  
  .option-card h3 {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .option-card p {
    font-size: 0.9rem;
    color: #555;
    font-weight: bold;
  }
  
  .option-card .action-link {
    text-decoration: none;
    display: inline-block;
    margin-top: 10px;
    font-size: 0.9rem;
    color: #007bff;
    font-weight: bold;
    border-radius: 5px;
    padding: 5px 10px;
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  
  .option-card .action-link:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .option-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  