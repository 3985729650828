.get-in-touch {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }
  
  .get-in-touch h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 50px;
    text-decoration: underline solid;
  }
  
  .cards-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  