.eligibility-card-container{
    /* border: 1px solid black; */
    display: flex;
    margin :4rem;
    
}
.eligibility-card-left{
    width: 500px;
    height: 500px;
    border: 1px solid #32974E;
    padding: 1.3rem;
    line-height: 2rem;
    text-align: justify;
    border-radius: 16px;
    margin-left:5rem ;
    box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.1);
}
.eligibility-card-left h3{
    text-decoration: underline;
}
#eligibility-card-right{
    border: 1px solid #506AEC;
}