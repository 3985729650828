*{
    font-family: "Montserrat";
}
.outerbox{
    border: 1px solid #DAD8D8;
    outline: #DAD8D8;
    width: 300px;
    margin: 2.5px;
    height: 250px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: #DAD8D8;
}

.outerbox img{
    width: 86px;
    height: 86px;
}
.outerbox h3{
    font-weight: 600;
    font-size: 1rem;
}
.outerbox p{
    font-size: .6rem;
    margin-top: 4px;
    margin: 4px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-weight: 400;
    
}
.outerbox button{
    width: 250px;
    height: 30px;
    font-size:10px ;
    border-radius: 8px;
    font-weight: 600;
    margin-top: 26px;
    background-color: black;
    color: white;
    
}
#coordinate{
    position: relative;
    top: 15px;
    
}
