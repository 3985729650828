.cardabout {
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 230px;
    height:auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .cardabout img {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
  }
  
  .cardabout h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  
  .cardabout p {
    font-size: 14px;
    color: #555;
    margin-bottom: 15px;
  }
  
  .cardabout button {
    background-color: #333;
    color: #fff;
    width:180px;
    height:35px;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cardabout button:hover {
    background-color: #000;
  }
  