.homeloan-line{
    position: relative;
    bottom: 507px;
    display: inline-block;
    width: auto;
    height: 340px;
    text-transform: capitalize;
    
}
.homeloan-line img{
    display: block;
}
#personalloan-line span {
    position: absolute;
    top: 250px;
    left: 110px;
    width: 500px;
    font-size: 1.5rem;
    background-color: transparent;
}
    
#btn-primary-personal{
    width: 120px;
    height: 40px;
    font-size: 12px;
    border-radius: 8px;
    font-weight: 600;
    margin-top: 26px;
    background-color: black;
    color: white;
    position: absolute;
    top: 410px;
    left: 110px;
}