table{
    width: 95%;
    border: 1px solid rgb(180, 173, 173);
    margin: 2rem;
    padding: 20px;
    height: 300px;
    border-radius: 16px;
    
}
tr{
    border-radius: 16px;
}
table td{
margin: 20px;

/* border-bottom: 1px solid red; */

}
td {
    border: 1px solid #ddd;
    padding: 8px;
}

/* Alternate row colors */
tr:nth-child(even) {
    background-color: #7d6565; /* Light color */
}

tr:nth-child(odd) {
    background-color: #494444; /* Dark color (default white) */
}
th {
    background-color: #d7dbdb;
    color: black;
}
#headingpara{
    margin: 2rem;
    font-size: 20px;
    font-weight: 600;
}