.why-bank-fincon-container{
    border: 1px solid black;
    width: 95%;
    margin-left: 2rem;
    margin-right: 2rem;
    display: flex;
    border-radius: 16px;
    background-color: whitesmoke;
}
.why-us-container-left{
    padding: 2rem;
    width: 80%;
    line-height: 1.5rem;
    background-color: whitesmoke;

    

}
.why-us-container-left p{
    width: 96%;
    text-align: justify;
    background-color: whitesmoke;


}
#h2-heading{
    background-color: whitesmoke;
    text-decoration: underline;

}
.why-us-container-left p b{
    background-color: whitesmoke;

    
}
#loanimg{
    position: relative;
    right: 2rem;
    top: 10rem;
    background-color: whitesmoke;

}
