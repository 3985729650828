.introduction-container{
    width: 95%;
    margin: 2rem;
    border: 1px solid rgb(185, 185, 185);
    border-radius: 16px;
    padding: 2rem;
    display: flex;
}
.left-intro-half{
    width: 60%;

}
.right-intro-half{
    width: 40%;
}
.right-intro-half img{
    width: 460px;
    height: 320px;
    position:relative;
    float: inline-end;
    border-radius: 16px;
}
.feature-min{
    border-bottom: 1px solid black;
    width: 700px;
    display: flex;
}
.feature-min-item{
    margin: 1rem;
}
.feature-min-item span{
    font-size:3rem ;
    margin-left: 4rem;
    color: cadetblue;
    font-weight: 700;
}