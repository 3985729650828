#lap-loan-id span{
    background-color: rgb(251, 253, 253);
}
.laploan-line{
    position: relative;
    bottom: 507px;
    display: inline-block;
    width: auto;
    height: 340px;
    text-transform: capitalize;
    
}
.lpaloan-line img{
    display: block;
   
}
.laploan-line span {
    position: absolute;
    top: 500px;
    left: 110px;
    width: 500px;
    font-size: 1.5rem;
    background-color: transparent;
    border-radius: 16px;
    padding: 1rem;
}
.laploan-line button{
    position: absolute;
    top: 325px;
    left: 298px;
}
.btn-primary-lap-loan{
    width: 120px;
    height: 40px;
    font-size: 12px;
    border-radius: 8px;
    font-weight: 600;
    margin-top: 26px;
    background-color: rgb(195, 163, 128);
    color: white;
}
.btn-primary-lap-loan a{
    background-color: rgb(195, 163, 128);
    text-decoration: none;
    color: whitesmoke;
}
.laploan-line button{
    position: absolute;
    top: 670px;
    left: 150px;
}