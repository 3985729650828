
#service-catalogue-one{
    margin: 20px;
    width: 600px;
    border: none;
    
}
.banner-holder{
    margin-left: 10px;
    border: 1px solid #DAD8D8;
}
#holder{
    border: none;
}
#enclosing-div{
    border:1px solid #DAD8D8;
    margin: 100px;
}
#position{
    position: relative;
    top: 80px;
    border: none;
}
#design-img{
    position: relative;
    bottom: 720px;
    left: 62px;
}
#heroImage{
    position: relative;
    top: 100px;
}
#outerbox-button{
    width: 180px;
    height: 30px;
    
    
}
.insurance-container-box{
    /* border: 1px solid rgb(195, 190, 190); */
    display: flex;
    height: 520px;
    padding: 5px;
    overflow: hidden;
    
}
.card-holder-box{
    /* width:100%; */
    margin-left: 1rem;
    display: flex;
    height: auto;
    flex-wrap: wrap;
   

}
.card-holder-box h3{
    font-size: 12px;
}
#insurance-card{
    width: 100px;
}
#insurance-text-heading h3{
font-size: 16px;
}
#insurance-more-button{
    width: 95%;
    height: 35px;
}
#insurance-more-button a{
 color: white;
 background-color: black;
 text-decoration: none;
}