.labelContainer{
    display: flex;
    flex-wrap: wrap;
}
.Label-box{
    width: 350px;
    height: 130px;
    text-align: justify;
    border: 1px solid #C5B9B9;
    border-radius: 8px;
    margin: 1rem;
    padding-top:.7rem ;
    display: flex;
   
}
.Label-box h3{
    font-size: 1rem;
    font-weight: 500;
    padding-left: 3rem;
    padding-right: 1rem;
}
.Label-box p{
    font-size:10px ;
    padding-left: 3rem;
    padding-right: 1rem;
    position: relative;
    top: .7rem;
   
}

.containthis{
    width: 30px;
    height: 30px;
    
}
.containthis img{
    width: 30px;
    height: 30px;
    position: relative;
    left: 15px;
    top: -4px;
}