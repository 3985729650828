.lender-heading {
    font-size: 1rem;
  }
  
  .lender-heading h1 {
    margin-top: 2rem;
    font-weight: 400;
  }
  
  .marquee-box {
    margin-top: 3rem;
  }
  
  .marquee-box img {
    width: 200px;
    height: 200px;
    margin-left: 4rem;
  }
  
  /* Unique styling for the new images */
  #custom-size {
    width: 150px; /* Adjust width as per your requirement */
    height: 57px; /* Adjust height as per your requirement */
    margin-left: 4rem;
    position: relative;
    top: -65px;
  }
  
  /* Styling for specific exceptions */
  #exception-pnb {
    height: 60px;
    width: 200px;
    position: relative;
    top: -65px;
  }
  
  