.information-links-container{
    border-bottom: 1px solid black;
    margin: 2rem 0;
    width: 80%;
}
.information-links{
    display: flex;
    margin-left: 12rem;
   
}
.information-links p{
     margin: 2rem;
    
    
}
.information-links p a{
    color: black;
    font-weight: 600;
}
.information-links p a:hover {
    background-color: rgb(171, 223, 170);
    padding: 1rem;
    color: black;
    border-radius: 16px;
    font-weight: 600;
}