/* ContactUs.css */
body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: azure;
  }
  
  .contact-page {
    padding: 20px;
    text-align: center;
  }
  
  .header h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .header p {
    font-size: 1.2rem;
    margin-bottom: 40px;
  }
  
  .cards-container {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .card {
    background-color: #FAF9F6;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    padding: 20px;
    width: 350px;
    height: 220px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid black;
  }
  #contactus-heading{
    color: blueviolet;
  }
  
  .card h3 {
    font-size: 1.25rem;
    margin-bottom: 30px;
  }
  
  .card p {
    font-size: 0.95rem;
    color: #555;
    margin-bottom: 30px;
    font-weight: bold;
  }
  
  .card a {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    transition: color 0.3s ease;
    margin-top: auto;
    align-self: flex-start;
    transition: color 0.3s ease;
  }
  
  .card a:hover {
    color: #0056b3;
  }

  .card:hover{
    transform: scale(1.05);
    box-shadow:  0 6px 10px rgba(0, 0, 0, 0.15);
  }
  