.emi-container {
  position: sticky;
  z-index: 1000; /* Ensures the sticky note appears on top */
  background-color: transparent;
  margin-left: 20px;
  bottom: 350px;
}

.toggle-button {
  background-color: #f39c12;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.emi-sticky-note {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  background-color: #f8f4d8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 8px;
  z-index: 1001;
}

.emi-sticky-note h3 {
  margin: 0 0 10px;
  background-color: #e9ecd8;
}

.emi-sticky-note input {
  width: calc(100% - 20px);
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.emi-sticky-note button {
  width: 100%;
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.emi-result {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
  font-weight: bold;
  background-color: #d8df13;
}
