.unique-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .unique-footer {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Align all items to the same vertical height */
    flex-wrap: wrap;
    gap: 20px;
    border-top: 1px solid #ccc;
    padding: 20px 0;
  }
  
  .unique-footer-col {
    flex: 1 1 20%; /* Equal width columns */
    min-width: 150px;
  }
  
  .unique-brand {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000;
  }
  
  .unique-brand-img {
    position: relative;
    right: -10px;
  }
  
  .unique-footer h5,
  .unique-footer h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .unique-footer ul {
    list-style: none;
    padding: 0;
  }
  
  .unique-footer ul li {
    margin-bottom: 5px;
  }
  .unique-position{
    position: relative;
    top: -50px;
    right: 100px;
  }
  #little-text{
    font-size: 9px;
    width: 200px;
    position: relative;
    top: 11px;
    left: 15px;
  }
  
  .unique-footer ul li a {
    text-decoration: none;
    color: #666;
    font-size: 14px;
  }
  
  .unique-footer ul li a:hover {
    text-decoration: underline;
    color: #000;
  }
  
  .unique-social-col {
    display: flex;
    flex-direction: column; /* Stack content vertically */
    /* align-items: flex-end; Align content to the right */
    justify-content: center; /* Center align within the row */
  }
  
  .unique-social-links {
    display: flex;
    gap: 10px; /* Add space between social icons */
  }
  
  .unique-social-links a {
    color: #666;
    font-size: 18px;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .unique-social-links a:hover {
    color: #000;
  }
  
  .unique-footer-text {
    font-size: 12px;
    color: #666;
  }
  