.mid-section {
  width: 875px;
  height: 220px;
  border-radius: 16px;
  border: 1px solid black;
  margin: 60px auto 0; 
  display: flex; 
  justify-content: center;
  align-items: center; 
  background-color: white; 
  padding: 10px;
  flex-direction: column;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

h2.section-title{
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;  
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


/*Grid container*/
.mid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 20px; 
  width: 100%; 
  justify-items: center;
  align-items: center; 
}

.mid-item {
  text-align: center;
  font-family: "Inter", sans-serif;
}


.mid-item h3 img {
  width: 50px; 
  height: auto;
}

.mid-item p {
  margin-top: 8px;
  font-size: 14px;
  color: #333;
}