.about-us-title {
    margin: 0 auto;
    text-align: center;
    width: 150px;
    height: 40px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-decoration: underline solid;
    color: black;
    font-size: 32px;
    margin-bottom: 30px;
  }
  
  #about-us {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 100%; 
    height: 100%; 
    position: relative; 
  }

  .about-us-section {
    width: 1170px;
    height: 320px;
    border-radius: 16px;
    border: 1px solid #6D7628; 
    display: flex; /* Flexbox to arrange items side by side */
    justify-content: space-between; 
    padding: 20px; 
    box-sizing: border-box; 
    gap: 20px;
  }

  .about-us-left, .about-us-right{
    width: 48%;
    height: 100%;
  }

  /*Left section*/
  .about-us-left{
    background-color: #f9f9f9;
    border-radius: 12px;
    font-family: "Inter", sans-serif;
  }

  .about-us-left h2 {
    font-weight: 500;
    font-size: 32px;
    color: black;
  }

  #abt-first-para {
    font-weight: 500;
    font-size: 16px;
    text-align: justify;
  }
  
  #abt-second-para {
    font-weight: 400;
    font-size: 12px;
    text-align: justify;
  }

  .about-us-btn {
    width: 155px;
    height: 38px;
    border-radius: 7.5px;
    background-color:#3F4645;
    color: white;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
  }

  .about-us-btn:hover{
    background-color: #3386b6;;
  }
  

  /*Right section*/
  .about-us-right{
    background-color: #f1f1f1; 
    border-radius: 12px;
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px; 
    align-items: center;
    
  }

  .stat-item {
    text-align: center; 
    font-family: 'Inter', sans-serif;
    margin-bottom: 20px;
  }

  .stat-item p {
    font-size: 16px; 
    color: black; 
    margin-top: 15px;
  }

  .stat-item h3, .stat-item p{
    margin: 0;
    padding: 0;
    line-height: 1;
  }

  #experience h3 {
    color: #EC7D7E;
    font-size: 50px; 
  }
  
  #loans h3 {
    color: #539F71;
    font-size: 50px; 
  }
  
  #lenders h3 {
    color: #688B90;
    font-size: 50px; 
  }
  
  #insurance h3 {
    color: #1A8392;
    font-size: 50px; 
  }
  
  #interest-rates h3 {
    color: #DBB076;
    font-size: 50px; 
  }
  
  #minimal-docs h3 {
    color: #A8DADC;
    font-size: 50px; 
  }