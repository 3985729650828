.skewed-box {
    width: 1140px;
    height: 600px;
    background-color: #FFD700; /* Yellow color */
    border: 1px solid #A49A9A;
    border-radius: 16px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    clip-path: polygon(75% 15%, 100% 0, 100% 100%, 25% 85%, 0 100%, 0 0);
    position: relative;
    margin: 50px auto;
    padding: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .content {
    padding: 40px;
    font-family: 'Inter', sans-serif;
    color: black;
    text-align: justify;
  }
  
  .content h2 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center;
    text-decoration:underline solid;
  }
  
  .content p {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 15px;
    color:#3D633B;
  }