.service-container{
   margin-top: 50px;
    height: 900px;
    
}
#left-marg{
    margin-left: 80px;
}
.service-catalogue-one{
    /* border: 1px solid #DAD8D8; */
    display:flex;
    width: 80%;
}
.service-catalogue-two{
    /* border: 1px solid #DAD8D8; */
    display:flex;
    width: 80%;
}
.holder{
    border: 1px solid #DAD8D8;
    width: 80%;
    margin-top: 50px;
    padding: 30px;
    border-radius: 16px;
}
.btn-view-more{
    width: 80%;
    height: 30px;
    background-color: black;
    color: aliceblue;
    margin-top:12px ;
    border-radius: 8px;
}
