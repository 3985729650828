/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
  }
  
  /* Navbar Container */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #FCFDF8;

    height: 80px;
    font-family: 'inter';
  }
  
  /* Logo and Name */
  .navbar-logo {
    display: flex;
    align-items: center;
  }
  
  .navbar-logo img {
    width: 85px;
    height: 70px;
    margin-right: 10px;
    position: relative;
    top: 0rem;
    right: -1px;
  }
  
  .navbar-logo span {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    position: absolute;
    left: 120px;
    top: 20px;
  }
#tagline {
    font-size: 13px;
    color: #6a6363;
    position: absolute;
    left: 120px;
    top: 45px;
    font-weight: 400;
  }
  
  /* Navbar Links */
  .navbar-links {
    display: flex;
    gap: 20px;
  }
  
  .navbar-links a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    transition: color 0.3s ease;
  }
  
  .navbar-links a:hover {
    color: #007bff;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .navbar-links {
      flex-direction: column;
      gap: 10px;
      display: none; /* Hide links by default on small screens */
      position: absolute;
      top: 60px;
      right: 20px;
      background-color: #f8f9fa;
      padding: 10px;
      border: 1px solid #ddd;
    }
  
    .navbar-links.active {
      display: flex; /* Show links when active */
    }
  
    .navbar-toggler {
      display: block;
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
    }
  }
  
  /* Toggler Button (hidden on larger screens) */
  .navbar-toggler {
    display: none;
  }
  .skyblue-button {
    background-color: skyblue; /* Button background color */
    color: white; /* Text color */
    font-size: 16px; /* Font size */
    padding: 10px 20px; /* Padding inside the button */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth hover transition */
    position: relative;
    top: -10px;
  }
  
  .skyblue-button:hover {
    background-color: #1e90ff; /* Darker sky-blue on hover */
  }
  
  